import React, { createRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"

import { CarouselContext } from "./carousel-provider"

const CarouselSlider = React.memo(
  ({
    children,
    width,
    height,
    duration = 300,
    noSlide = false,
    isHorizontal = true,
    clickable = false,
  }) => {
    const context = useContext(CarouselContext)
    const sliderRef = createRef()
    const flexDirection = isHorizontal ? "row" : "column"

    useEffect(() => {
      setChildrenLength()
      isHorizontal ? slideHorizontally() : slideVertically()
    },[]);

    const slideHorizontally = () => {
      const slideWidth = sliderRef.current.clientWidth
      const translate = context.activeIndex * slideWidth
      sliderRef.current.style.transform = `translate3d(-${translate}px, 0, 0)`
    }

    const slideVertically = () => {
      const slideHeight = sliderRef.current.clientHeight
      const translate = context.activeIndex * slideHeight
      sliderRef.current.style.transform = `translate3d(0,-${translate}px,0)`
    }

    const setChildrenLength = () => {
      const totalChildren = React.Children.count(children)

      if (context.childrenCount !== totalChildren) {
        context.handleChildrenCount(totalChildren)
      }
    }

    const handleClick = direction => {
      const { activeIndex, handleSlideChange } = context
      let index

      if (direction === "prev") {
        index = activeIndex - 1
      } else if (direction === "next") {
        index = activeIndex + 1
      }

      handleSlideChange(index)
    }

    return (
      <>
        <div
          className="relative mx-auto overflow-hidden flex justify-center"
          style={{ width, height }}
        >
          <div
            ref={sliderRef}
            className="flex"
            style={{
              width,
              height,
              flexDirection,
              transition: noSlide
                ? null
                : `transform ${duration}ms ease-in-out`,
            }}
          >
            {children}
          </div>
          {clickable ? (
            <div className="absolute inset-0 flex justify-between bg-transparent">
              <button
                onClick={() => handleClick("prev")}
                className="h-full w-1/4 bg-transparent outline-none focus:outline-none"
              ></button>

              <button
                onClick={() => handleClick("next")}
                className="h-full w-1/4 bg-transparent outline-none focus:outline-none"
              ></button>
            </div>
          ) : null}
        </div>
      </>
    )
  }
)

CarouselSlider.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

export default CarouselSlider
