import React, { useLayoutEffect, useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import { useScrollPercentage } from "../../hooks/useScrollPercentage"
import Logo from "../images/logo"
import ScrollDownButtonHome from "../scroll-down-button-home"
import CallToActionQualify from "../call-to-action-qualify/call-to-action-qualify"
import CallToActionCalculate from "../call-to-action-calculate/call-to-action-calculate"

import { usePlacesWidget } from "react-google-autocomplete";

//import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

//import ShadowText from 'react-shadow-text';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {
//  faCoffee,
//  faAddressBook,
//  faAirFreshener,
//  faAmbulance,
//  faAtom,
//  faBus,
//  faCoins,
//  faDice,
//} from '@fortawesome/free-solid-svg-icons'

//import { OutboundLink } from "gatsby-plugin-google-analytics"
//const TRACKING_ID = "G-3ZFVE4Z75D"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);
//if (typeof window !== 'undefined') {
//ReactGA.pageview(window.location.pathname + window.location.search); }

export default function HomeHero({ play }) {

  const parentRef = useRef()
  const videoRef = useRef()
  const [opacity, setOpacity] = useState(0)
  const [percentage, windowHeight] = useScrollPercentage()
  useEffect(() => {
    setOpacity(percentage)
  }, [percentage])

  useLayoutEffect(() => {
    if (play) {
      parentRef.current.addEventListener("animationend", () => {
        const spans = parentRef.current.querySelectorAll(".hide")
        spans.forEach(span => span.classList.add("hidden"))
        parentRef.current.classList.remove("fadeIn")
      })
    }
  }, [])


  //////////////////////// GOOGLE AUTOCOMPLETE ADDRESS //////////////////////
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAFpUvr6VeIKf--CTji6Y_Cd0odWV3u5DE",
    onPlaceSelected: (place) => {
      console.log(place);
      
      //UPDATE LOCAL VALUE EVERY TIME WHEN INPUT CHANGED
      //const requested_address = place.formatted_address;
      localStorage.setItem('requested_address', place.formatted_address);
      //alert(place.formatted_address)
      //checkSubmitButton();

      //alert(sessionStorage.getItem('requested_address'))

      //window.location.href = "/design-builder";
      //

    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "us" },
    },
  });

function redirectToDesignBuilder(){
  if (localStorage.getItem('requested_address') !== '') { 
    window.location.href = "/design-builder";
  }
}

useEffect(() => {
  localStorage.setItem('requested_address', '')
}, [])


  return (
    <div className="relative flex items-center h-screen w-full overflow-hidden z-0 md:bg-black mobile-hero-bg hero-background" 
    
    
    
    >
      <div
        className="fixed inset-0"
        style={{
          zIndex: "-2",
        }}
      >

        {/*}
        <video
          ref={videoRef}
          loop
          muted
          autoPlay
          playsInline
          className="absolute inset-0 hidden md:block"
          style={{
            zIndex: "-1",
            width: "100vw",
            height: "56.25vw",
            minWidth: "177.77vh",
            minHeight: "100vh",
          }}
        >
          <source
            type="video/mp4"
            //src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fcrop_full_high_compression_jw1wez.mp4?alt=media&token=cc4d6108-79c9-4dba-9a66-392589344185"
          ></source>
        </video>

         <video
          loop
          muted
          autoPlay
          playsInline
          className="absolute block md:hidden"
          style={{
            zIndex: "-1",
            width: "100vw",
            height: "56.25vw",
            minWidth: "55.77vh",
            minHeight: "100vh",
            right: "0",
            left: "-500px",
          }}
        >
          <source
            type="video/mp4"
            src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fcrop_full_high_compression_jw1wez.mp4?alt=media&token=cc4d6108-79c9-4dba-9a66-392589344185"
          ></source>
        </video>
        https://api.checksolarcost.com/v1/getImageFile/Modal-Homepage-Background-Image-Feb2023.jpg
        */}

        <div
          style={{
            zIndex: -1,
            opacity,
            transition: "opacity 200ms ease-out",
          }}
          className="absolute w-full h-full bg-black"
        ></div>
      </div>



      <div
        className="fixed ml-5 lg:ml-32 pb-24 lg:pb-12 md:pb-0"
        style={{ zIndex: "-1" }}
      >
        
          <h1 className="text-white">Get acurate quote of your</h1>
          <h1 className="text-white">solar project in seconds</h1>
          <br></br>
          <h4 className="text-white">No email or phone requared</h4>

          <br></br>

        <div ref={parentRef} className="font-bradford inline fadeIn"></div>

        <div className="flex flex-wrap font-light py-1">
          <input
            ref={ref}
            //onChange={e => handleUserInputChange(e.target)} ✓  ✗ ✗ ✓ 
            //id="address"
            className=""
            style={{'border-radius':'10px','padding':'20px','min-width':'500px', 'height':'50px', 'margin-right':'10px'}}
            //name="address"
            //autocomplete="random-string"
            //type="text" 
            name="requested_address"
            id="requested_address"
            autocomplete="requested_address"
            placeholder={"Enter your address"}
            //value={"HERE"}
            //onChange={() => {isAddressValid();}}
          />

          <div
            //to="/how-it-works"
            style={{'cursor':'pointer', 'border-radius':'10px'}}
            className="transition transition-delay-longer bg-white text-white border bg-green-900 border-green-900 hover:text-green-900 hover:bg-white hover:border-white w-60  px-8 py-3 text-center tracking-wide modal-button"
            onClick={() => {redirectToDesignBuilder();}}
          >
            Get Quote
          </div>
        </div>

      </div>

      <ScrollDownButtonHome scrollTo={windowHeight}></ScrollDownButtonHome>
    </div>
    
  )
}