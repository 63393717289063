import React from "react"
import ExpandingListItem from "./ui/list-item"

export default function FaqSection({ faqs }) {
  return (
    <section className="relative text-center py-16 px-6 lg:px-24 xl:px-48 bg-white">
      <h3 className="py-10 text-gray-600 tracking-wide font-light">
        You ask. <span className="font-bradford">We answer.</span>
      </h3>
      <ul>
{/*
        {faqs && faqs.length ? (
          faqs.map(({ node }, index) => (
            <ExpandingListItem
              key={index}
              title={node.frontmatter.question}
              data={node.frontmatter.answer}
            ></ExpandingListItem>
          ))
        ) : (
          <li>No FAQS yet.</li>
        )}

        */}

      </ul>
    </section>
  )
}
