import React, { useState } from "react"

export const CarouselContext = React.createContext()

export default function CarouselProvider({ children }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [childrenCount, setChildrenCount] = useState(0)

  const handleSlideChange = index => {
    if (index === activeIndex) {
      return
    } else if (index < 0) {
      setActiveIndex(childrenCount - 1)
    } else if (index >= childrenCount) {
      setActiveIndex(0)
    } else {
      setActiveIndex(index)
    }
  }

  const handleChildrenCount = count => {
    if (count === childrenCount) {
      return
    }

    setChildrenCount(count)
  }

  return (
    <CarouselContext.Provider
      value={{
        activeIndex,
        handleSlideChange,
        childrenCount,
        handleChildrenCount,
      }}
    >
      {children}
    </CarouselContext.Provider>
  )
}
