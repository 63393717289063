import React, { useEffect, useRef } from "react"

export default function PhoneVideoThree() {
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]

        if (entry.intersectionRatio >= 1) {
          setTimeout(() => {
            entry.target.play().catch(console.log)
          }, 500)
        } else {
          entry.target.pause()
          entry.target.currentTime = 0
        }
      },
      {
        threshold: 1,
      }
    )

    observer.observe(ref.current)

    return () => {
      observer.unobserve(ref.current)
    }
  }, [])

  return (
    <video ref={ref} muted playsInline autoPlay loop>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fmodal-home-phone-video-3.mp4?alt=media&token=be8a85b9-96a2-4120-9d4d-3387ed4df875"
        type="video/mp4"
      ></source>
    </video>
  )
}
