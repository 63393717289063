import React, { useContext } from "react"
import PropTypes from "prop-types"
import { CarouselContext } from "./carousel-provider"

const CarouselTracking = React.memo(
  ({ children, activeClass = "", showIndex = false }) => {
    const context = useContext(CarouselContext)
    const child = React.Children.only(children)
    const dots = []

    for (let i = 0; i < context.childrenCount; i++) {
      const isActive = i === context.activeIndex

      const element = React.cloneElement(child, {
        ...child.props,
        key: i,
        className: `${child.props.className} ${isActive ? activeClass : ""}`,
        onClick: context.handleSlideChange.bind(this, i),
        children: showIndex ? i + 1 : null,
      })

      dots.push(element)
    }

    return <>{dots.map(dot => dot)}</>
  }
)

CarouselTracking.propTypes = {
  activeClass: PropTypes.string,
  showIndex: PropTypes.bool,
}

export default CarouselTracking
