import React, { useEffect, useState, createRef } from "react"

import lottie from "lottie-web"
import animation from "../animations/loader.json"

export default function LottieLoader({ onComplete }) {
  const ref = createRef()
  const animationContainer = createRef()
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    const onEnd = () => {
      onComplete(true)
    }

    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation,
      loop: false,
      autoplay: true,
    })
    anim.setSpeed(2)

    anim.addEventListener("complete", () => {
      setIsComplete(true)
    })

    ref.current.addEventListener("transitionend", onEnd)
  }, [])

  return (
    <div
      ref={ref}
      className="fixed inset-0 flex justify-center items-center z-50 bg-green-900"
      style={{
        opacity: isComplete ? 0 : 1,
        transition: "all 300ms ease-in",
      }}
    >
      <div
        className="animation-container max-w-md m-auto"
        ref={animationContainer}
      />
    </div>
  )
}
