import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { window, document } from "browser-monads"

import CarouselProvider from "../carousel/carousel-provider"
import CarouselSlider from "../carousel/carousel-slider"
import CarouselTracking from "../carousel/carousel-tracking"
import CarouselArrow from "../carousel/carousel-arrow"

export default function HomeCarousel() {
  const {
    sliderImgs: { edges: images },
  } = useStaticQuery(graphql`
    query HomeCarouselQuery {
      sliderImgs: allFile(
        filter: { relativePath: { regex: "/home-slider/" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const [width, setWidth] = useState(
    document.documentElement.clientWidth >= 1100
      ? 1100
      : document.documentElement.clientWidth >= 800
      ? document.documentElement.clientWidth - 150
      : document.documentElement.clientWidth - 50
  )
  useEffect(() => {
    setHeight(width / 1.5)
  }, [width])

  const [height, setHeight] = useState(width / 1.5)

  useEffect(() => {
    const onResize = () => {
      const docWidth = document.documentElement.clientWidth

      if (docWidth >= 1100) {
        setWidth(1100)
      } else if (docWidth >= 800) {
        setWidth(docWidth - 150)
      } else {
        setWidth(docWidth - 50)
      }
    }

    window.addEventListener("resize", onResize)

    return () => window.removeEventListener("resize", onResize)
  }, [])

  const getContent = index => {
    switch (index) {
      case 0:
        return (
          <div className="w-full h-full mb-10">
            <h2 className="pb-8 text-gray-600 tracking-wide font-light">
              An income property in <span className="font-bradford">your</span>{" "}
              yard.
            </h2>
            <p className="text-gray-500 tracking-wide font-light pb-12">
              Transform your property into the ideal investment. Generate
              additional income by renting your Modal out for long or short-term
              use and let it pay for itself.
            </p>
            <Link
              to="/products"
              className="transition transition-delay-longer bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              See Modals
            </Link>
          </div>
        )
      case 1:
        return (
          <div className="w-full h-full mb-10">
            <h2 className="pb-8 text-gray-600 tracking-wide font-light">
              Function<br></br>
              <span className="font-bradford">via</span> flexibility.
            </h2>
            <p className="text-gray-500 tracking-wide font-light pb-12">
              Modal is designed to be as versatile as you. Create a new
              dwelling, workspace, art studio, home gym, or personal getaway,
              with custom options to fit your vision.
            </p>
            <Link
              to="/products"
              className="transition transition-delay-longer bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              See Modals
            </Link>
          </div>
        )
      case 2:
        return (
          <div className="w-full h-full mb-10">
            <h2 className="pb-8 text-gray-600 tracking-wide font-light max-w-sm">
              Increase <span className="font-bradford">your </span> home’s
              value.
            </h2>
            <p className="text-gray-500 tracking-wide font-light max-w-md pb-12">
              A Modal in your backyard is one of the easiest, most assured ways
              to increase the value of your property, without any of the hassle
              of conventional builds.
            </p>
            <Link
              to="/products"
              className="transition transition-delay-longer bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              See Modals
            </Link>
          </div>
        )
      case 3:
        return (
          <div className="w-full h-full mb-10">
            <h2 className="pb-8 text-gray-600 tracking-wide font-light max-w-sm">
              Affordable <span className="font-bradford">and </span>
              aesthetic.
            </h2>
            <p className="text-gray-500 tracking-wide font-light max-w-md pb-12">
              High-quality construction and design are our guiding principles.
              With efficient manufacturing and flexible financing options, we’ve
              made Modal affordable and accessible for everyone.
            </p>
            <Link
              to="/products"
              className="transition transition-delay-longer bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button"
            >
              See Modals
            </Link>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <section className="relative justify-between bg-white items-center py-20">
      <CarouselProvider>
        <div className="relative w-full h-full">
          <CarouselArrow
            className="absolute hidden lg:block left-0 lg:block"
            size="50"
            direction="left"
            style={{
              top: "50%",
              left: "40px",
            }}
          ></CarouselArrow>

          <CarouselArrow
            className="absolute hidden lg:block right-0 lg:block"
            size="50"
            style={{
              top: "50%",
              right: "40px",
            }}
          ></CarouselArrow>

          <CarouselArrow
            className="absolute block lg:hidden left-0 lg:block"
            size="50"
            direction="left"
            style={{
              top: "12%",
              left: "40px",
            }}
          ></CarouselArrow>

          <CarouselArrow
            className="absolute block lg:hidden right-0 lg:block"
            size="50"
            style={{
              top: "12%",
              right: "40px",
            }}
          ></CarouselArrow>

          <CarouselSlider width={"100vw"} height={"100%"} duration={400}>
            {images.map(({ node }, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col w-full h-full flex-none mx-auto px-8 lg:px-20 xl:px-48 lg:flex-row"
                >
                  <div
                    className="flex-1 w-full flex justify-center items-center mx-auto"
                    style={{ maxWidth: "750px" }}
                  >
                    <Image
                      className="w-full h-auto"
                      objectFit="cover"
                      fluid={node.childImageSharp.fluid}
                      alt={node.name}
                    />
                  </div>
                  <div className="hidden flex-none border mx-6 lg:block"></div>
                  <div
                    className="flex-none w-full px-4 mt-8 mx-auto lg:mt-0 lg:w-1/3"
                    style={{ maxWidth: "750px" }}
                  >
                    {getContent(i)}
                  </div>
                </div>
              )
            })}
          </CarouselSlider>
          <div className="flex justify-center items-center mt-0 lg:mt-6 lg:pr-95">
            <CarouselTracking activeClass="bg-gray-500">
              <button className="bg-gray-300 w-2 h-2 mx-3 cursor-pointer focus:outline-none"></button>
            </CarouselTracking>
          </div>
        </div>
      </CarouselProvider>
    </section>
  )
}
