import React, { useEffect, useRef } from "react"

export default function PhoneVideoFour({
  className = "",
  width = "",
  height = "",
}) {
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]

        if (entry.intersectionRatio >= 1) {
          setTimeout(() => {
            entry.target.play().catch(console.log)
          }, 500)
        } else {
          entry.target.pause()
          entry.target.currentTime = 0
        }
      },
      {
        threshold: 1,
      }
    )

    observer.observe(ref.current)

    return () => {
      observer.unobserve(ref.current)
    }
  }, [])

  return (
    <video className={className} ref={ref} muted playsInline autoPlay loop>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fmodal-home-phone-video-4.2e.mp4?alt=media&token=3ec2d68b-bc9e-4865-9556-30a258d15dc7"
        type="video/mp4"
      ></source>
    </video>
  )
}
