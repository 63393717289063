import React from "react"
import { Link } from "gatsby"

import ArrowSubmit from "./../images/arrow-submit"

export default function StickyMobileCta() {
  return (
    <>
      <section className="bottom-0 fixed flex flex-row justify-between block md:hidden bg-green-900 py-4 px-6 z-40 w-full text-white">
       
        <Link to="/get-started">
          <div className="font-light tracking-wide">Get Started</div>
        </Link>

        <Link to="/get-started">
          <div className="fill-current text-white">
            <ArrowSubmit></ArrowSubmit>
          </div>
        </Link>
        
      </section>
    
    </>
  )
}
