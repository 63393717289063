import React from "react"

export default function StarIcons({ fill }) {
  return (
    <svg
      style={{
        fill,
      }}
      className="w-48 h-6 m-auto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 158.75 22.79"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect
            x="2.1"
            y="16.42"
            width="10.81"
            height="2.35"
            transform="translate(-11.38 14.58) rotate(-57.83)"
          />
          <rect
            x="14.07"
            y="12.2"
            width="2.35"
            height="10.79"
            transform="translate(-7.03 10.82) rotate(-32.17)"
          />
          <rect
            x="12.65"
            y="8.4"
            width="10"
            height="2.35"
            transform="translate(-2.21 6.64) rotate(-20.06)"
          />
          <rect
            x="3.93"
            y="4.57"
            width="2.35"
            height="10"
            transform="translate(-5.64 11.08) rotate(-69.94)"
          />
          <polygon points="10.2 0 10.2 10.19 10.2 10.19 12.55 10.19 12.55 10.19 12.55 0 10.2 0" />
          <rect
            x="70.1"
            y="16.42"
            width="10.81"
            height="2.35"
            transform="translate(20.41 72.14) rotate(-57.83)"
          />
          <rect
            x="82.07"
            y="12.2"
            width="2.35"
            height="10.79"
            transform="translate(3.41 47.02) rotate(-32.17)"
          />
          <rect
            x="80.65"
            y="8.4"
            width="10"
            height="2.35"
            transform="translate(1.91 29.96) rotate(-20.06)"
          />
          <rect
            x="71.93"
            y="4.57"
            width="2.35"
            height="10"
            transform="translate(39.03 74.95) rotate(-69.94)"
          />
          <polygon points="78.2 0 78.2 10.19 78.2 10.19 80.55 10.19 80.55 10.19 80.55 0 78.2 0" />
          <rect
            x="36.1"
            y="16.42"
            width="10.81"
            height="2.35"
            transform="translate(4.52 43.36) rotate(-57.83)"
          />
          <rect
            x="48.07"
            y="12.2"
            width="2.35"
            height="10.79"
            transform="translate(-1.81 28.92) rotate(-32.17)"
          />
          <rect
            x="46.65"
            y="8.4"
            width="10"
            height="2.35"
            transform="translate(-0.15 18.3) rotate(-20.06)"
          />
          <rect
            x="37.93"
            y="4.57"
            width="2.35"
            height="10"
            transform="translate(16.69 43.02) rotate(-69.94)"
          />
          <polygon points="44.2 0 44.2 10.19 44.2 10.19 46.55 10.19 46.55 10.19 46.55 0 44.2 0" />
          <rect
            x="104.1"
            y="16.42"
            width="10.81"
            height="2.35"
            transform="translate(36.31 100.92) rotate(-57.83)"
          />
          <rect
            x="116.07"
            y="12.2"
            width="2.35"
            height="10.79"
            transform="translate(8.63 65.12) rotate(-32.17)"
          />
          <rect
            x="114.65"
            y="8.4"
            width="10"
            height="2.35"
            transform="translate(3.98 41.63) rotate(-20.06)"
          />
          <rect
            x="105.93"
            y="4.57"
            width="2.35"
            height="10"
            transform="translate(61.36 106.89) rotate(-69.94)"
          />
          <polygon points="112.2 0 112.2 10.19 112.2 10.19 114.55 10.19 114.55 10.19 114.55 0 112.2 0" />
          <rect
            x="138.1"
            y="16.42"
            width="10.81"
            height="2.35"
            transform="translate(52.21 129.7) rotate(-57.83)"
          />
          <rect
            x="150.07"
            y="12.2"
            width="2.35"
            height="10.79"
            transform="translate(13.85 83.22) rotate(-32.17)"
          />
          <rect
            x="148.65"
            y="8.4"
            width="10"
            height="2.35"
            transform="translate(6.04 53.29) rotate(-20.06)"
          />
          <rect
            x="139.93"
            y="4.57"
            width="2.35"
            height="10"
            transform="translate(83.7 138.83) rotate(-69.94)"
          />
          <polygon points="148.55 10.19 148.55 10.19 148.55 0 146.2 0 146.2 10.19 146.2 10.19 148.55 10.19" />
        </g>
      </g>
    </svg>
  )
}
