import React, { useEffect, useRef } from "react"

export default function HomeVideoExpand() {
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]

        if (entry.intersectionRatio >= 1) {
          setTimeout(() => {
            entry.target.play().catch(console.log)
          }, 0)
        } else {
          entry.target.pause()
          entry.target.currentTime = 0
        }
      },
      {
        rootMargin: "0px 0px -100px 0px",
        threshold: 1,
      }
    )

    observer.observe(ref.current)

    return () => observer.unobserve(ref.current)
  }, [])

  return (
    <section className="relative flex flex-row justify-center items-center lg:pt-24 lg:pb-0 py-10 lg:p-0 p-10 bg-white">
      <div>
        <video ref={ref} muted autoplay playsInline>
          <source
            // src="https://res.cloudinary.com/modal-living/video/upload/ac_none,q_83,vc_auto/v1583172905/Modal%20Living%20Video/modal-home-exploded-final_qdvmpx.mp4"
            src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fmodal-home-exploded-final_qdvmpx.mp4?alt=media&token=e6ab85bd-83ce-440e-82d9-51ede26a45"
            type="video/mp4"
          ></source>
        </video>
      </div>
    </section>
  )
}
