import React, { useRef, useEffect, useState } from "react"
import Image from "gatsby-image"
import TargetIcon from "../images/target-icon"
import "./home-product-preview.css"
import { Link } from "gatsby"

export default function HomeProductPreview({ product }) {
  const containerRef = useRef()
  const [showContent, setShowContent] = useState(false)
  const toggleContent = () => {
    setShowContent(!showContent)
  }

  useEffect(() => {
    containerRef.current.addEventListener("mousedown", toggleContent)

    return () => {
      containerRef.current.removeEventListener("mousedown", toggleContent)
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className="relative card-container mx-4 lg:m-3 my-4 lg:m-w-1/3 overflow-hidden cursor-pointer bg-gray-900"
      style={{ height: "" }}
    >

      <img className="image h-full" style={{ maxWidth: '100%', objectFit: 'cover', objectPosition: "center" }} src={product.picture} alt="Logo" />;

      <div className="absolute top-0 right-0 flex flex-row justify-end items-center p-2 z-10">
        <h5 className="font-maxeville font-light text-white pt-2 arrow-button">
          {product.title}
        </h5>
        <button
          className="text-white ml-4 outline-none focus:outline-none"
          onClick={toggleContent}
        >
          <TargetIcon rotate={showContent}></TargetIcon>
        </button>
      </div>

      <div className="card-preview text-white text-center py-6 pt-10 px-10">
        <p>
          <small className="font-light tracking-wide">
            {product.description}
          </small>
        </p>
      </div>

      <div
        className="card-content text-left text-white pb-10 font-light tracking-wide bg-gray-700 flex flex-col"
        style={{
          transform: showContent ? "translateY(0%)" : "translateY(100%)",
        }}
      >

        <img className="w-full top-0 flex " imgStyle={{objectPosition: "left center",}} src={product.picture} alt="Logo" />

        <div className="px-6">
          <p className="text-xs md:text-sm py-10">
            {product.description}
          </p>

          <Link
            to={`/products/${product.link}`}
            className="transition transition-delay-longer bg-white border border-white hover:bg-transparent text-white text-gray-600 hover:text-white w-32 my-12 px-8 py-3 text-center font-bradford1 modal-button"
          >
            View {product.title}
          </Link>
        </div>
      </div>
    </div>
  )
}
