import React, { useEffect, useRef } from "react"

export default function PhoneVideoOne() {
  const ref = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0]

        if (entry.intersectionRatio >= 1) {
          setTimeout(() => {
            entry.target.play().catch(console.log)
          }, 500)
        } else {
          entry.target.pause()
          entry.target.currentTime = 0
        }
      },
      {
        threshold: 1,
      }
    )

    observer.observe(ref.current)

    return () => {
      observer.unobserve(ref.current)
    }
  }, [])

  return (
    <video ref={ref} muted playsInline autoPlay loop>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/modal-living.appspot.com/o/videos%2Fmodal-home-phone-video-1.mp4?alt=media&token=98a54a62-bb90-4373-803b-afc03f94572d"
        type="video/mp4"
      ></source>
    </video>
  )
}
