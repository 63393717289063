import React, { useContext } from "react"
import { CarouselContext } from "./carousel-provider"

export default function CarouselArrow({
  direction,
  size,
  style = {},
  className = "",
}) {
  const { handleSlideChange, activeIndex } = useContext(CarouselContext)

  const handleClick = () => {
    direction === "left"
      ? handleSlideChange(activeIndex - 1)
      : handleSlideChange(activeIndex + 1)
  }

  return (
    <button
      className={`z-10 opacity-50 focus:outline-none hover:opacity-100 ${className}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        transform: `rotate(${direction === "left" ? "180deg" : "0deg"})`,
        transition: "opacity 200ms ease",
        ...style,
      }}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29.52 23.04"
        className="w-full h-full"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon points="29.52 9.6 19.17 0 15 0 25.46 9.6 29.52 9.6" />
            <polygon points="15 23.04 19.16 23.04 29.52 13.44 25.5 13.44 15 23.04" />
          </g>
        </g>
      </svg>
    </button>
  )
}
