import React from "react"

import PhoneVideoOne from "./videos/phone-video-1"
import PhoneVideoTwo from "./videos/phone-video-2"
import PhoneVideoThree from "./videos/phone-video-3"
import PhoneVideoFour from "./videos/phone-video-4"
import PhoneVideoFive from "./videos/phone-video-5"
import "./phone-section.css"

export default function MobilePhoneSection() {
  return (
    <div className="block md:hidden">
      <section className="relative flex flex-col items-center pt-20 pb-6 px-6 phone-section-mobile">
        <div className="flex flex-col inline-block justify-between">
          <div className="px-16">
            <PhoneVideoOne></PhoneVideoOne>
          </div>
        </div>
        <div className="flex flex-col inline-block  text-white">
          <div className="flex flex-col font-light">
            <h2 className="py-6 lg:pr-20 font-light tracking-wide text-gray-600">
              Qualify.
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="font-light tracking-wide pb-6 text-gray-500">
              First, we’ll determine if your city and property qualify for
              Modal, then provide your pre-approval.
            </p>
          </div>
        </div>
      </section>

      <section className="relative flex flex-col items-center px-6 phone-section-mobile">
        <div className="flex flex-col inline-block justify-between">
          <div className="px-16 pb-6">
            <PhoneVideoTwo></PhoneVideoTwo>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between text-white">
          <div className="flex flex-col font-light">
            <h2 className="pb-6 lg:pr-20 font-light tracking-wide text-gray-600">
              Design
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="font-light tracking-wide pb-6 text-gray-500">
              Once you’ve been approved, create your ideal space by customizing
              your Modal’s layout, color, and features.
            </p>
          </div>
        </div>
      </section>

      <section className="relative flex flex-col items-center py-6 px-6 phone-section-mobile">
        <div className="flex flex-col inline-block justify-between">
          <div className="px-16 pb-6">
            <PhoneVideoThree></PhoneVideoThree>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between text-white">
          <div className="flex flex-col font-light">
            <h2 className="pb-6 lg:pr-20 font-light tracking-wide text-gray-600">
              Order
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="font-light tracking-wide pb-6 text-gray-500">
              When your Modal is designed, our team gets to work preparing and
              organizing all the necessary permits, plans, and processes.
            </p>
          </div>
        </div>
      </section>

      <section className="relative flex flex-col items-center py-6 px-6 phone-section-mobile">
        <div className="flex flex-col inline-block justify-between">
          <div className="px-16 pb-6">
            <PhoneVideoFour></PhoneVideoFour>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between text-white">
          <div className="flex flex-col font-light">
            <h2 className="pb-6 lg:pr-20 font-light tracking-wide text-gray-600">
              Build
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="font-light tracking-wide pb-10 text-gray-500">
              Our dedicated builders carefully craft your Modal to all of your
              specifications, ensuring each aspect is built to last.
            </p>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between">
          <div className="flex"></div>
        </div>
      </section>
      <section className="relative flex flex-col items-center pb-20 px-6 phone-section-mobile">
        <div className="flex flex-col inline-block justify-between">
          <div className="px-16 pb-6">
            <PhoneVideoFive></PhoneVideoFive>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between text-white">
          <div className="flex flex-col font-light">
            <h2 className="pb-6 lg:pr-20 font-light tracking-wide text-gray-600">
              Install
            </h2>
          </div>
          <div className="flex flex-col">
            <p className="font-light tracking-wide pb-10 text-gray-500">
              Lastly, your Modal is delivered directly to you, professionally
              installed in 24 hours, and ready for you to make it yours.
            </p>
          </div>
        </div>
        <div className="flex flex-col inline-block justify-between">
          <div className="flex"></div>
        </div>
      </section>
    </div>
  )
}
