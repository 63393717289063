import React, { useState, useEffect, createRef } from "react"
import Collapsible from "react-collapsible"
import TargetIcon from "../images/target-icon"

export default function ExpandingListItem({ title, data }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <li className="border-t border-gray-400 cursor-pointer font-light tracking-wide">
      <button
        className="w-full flex justify-between items-start text-left py-6 text-gray-600 outline-none focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{title}</p>
        <TargetIcon className="ml-4" rotate={isOpen}></TargetIcon>
      </button>

      <Collapsible
        trigger={null}
        open={isOpen}
        transitionTime={200}
        transitionCloseTime={150}
        easing={"ease-out"}
      >
        <div className="pb-4 text-gray-500 text-left">{data}</div>
      </Collapsible>
    </li>
  )
}
